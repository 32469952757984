import React, { useEffect, useState } from 'react'
import styles from './CoreSkill.module.scss'
import { getSectionDetails } from '../../utils/utils';
import Details from '../../components/Details/Details';

const CoreSkill = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(getSectionDetails('core skills'))
  },[])

  return (
    <div className={styles.coreSkillsContainer}>
      <div className={styles.coreSkillsWrapper}>
        <p className={styles.heading} id="core-skills">
          Core Skills
        </p>
            {
              <Details sections={sections} />
            }
      </div>
    </div>
  )
}

export default CoreSkill
