import React from 'react'
import styles from './Header.module.scss'
import competencies from '../../mockdata/competencies.json'
import { HashLink } from 'react-router-hash-link'

const Header = () => {
  return (
    <header className="header">
        <div className={styles.headerWrapper} >
            <div className={styles.catHeading}>
                <HashLink to="/">
                    Business Analysis
                </HashLink>
            </div>
            <div className={styles.links}>
                {
                    competencies != null && competencies.length > 0 && 
                        competencies.map(competency => 
                            <HashLink 
                                className={styles.HashLink} 
                                key={competency.pid} 
                                to={`${competency.pageUrl}#${competency.pageUrl}`}
                            >
                                {competency.name}
                            </HashLink> 
                        )
                }
            </div>
        </div>
    </header>
  )
}

export default Header
