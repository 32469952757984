import React from 'react'
import { HashLink } from 'react-router-hash-link'
import styles from './Overview.module.scss'
import Arrow from '../../assests/Arrow'

const Overview = ({
    overview: {
        title,
        description,
        imgSrc,
        pageUrl,
        imageDirection
    }
}) => {
    return (
        <div 
            className={styles.overviewWrapper} 
            style={{flexDirection: imageDirection === 'left' ? 'row-reverse' : 'row'}}
        >
            <div className={styles.overview}>
                <div className={styles.heading}>
                    {title}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
                <div className={styles.readMore}>
                    <HashLink to={`${pageUrl}#${pageUrl}`}>
                        Read more
                        <Arrow />
                    </HashLink>
                </div>
            </div>
            <div className={styles.image}>
                <img src={imgSrc} alt={title}/>
            </div>
        </div>
    )
}

export default Overview
