import React from 'react'
import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer>
        <div className={styles.stayUpdatedContainer}>
            <div className={styles.stayUpdatedWrapper}>
                <p className={styles.stayUpdated}>Stay Updated</p>
                <p className={styles.subscribeLabel}>"Stay in the know! Join our community of learners and enthusiasts - don't miss out on valuable insights that can elevate your Business Analysis skills.</p>
                <div className={styles.btnContainer}>
                    <a href='https://forms.office.com/e/Rc8rEHDzH7' target='_blank'> 
                    <input className={styles.subscribeBtn} type='button' value="Stay Connected" />
                    </a>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer
