import React, { useEffect, useState } from 'react'
import styles from './ToolsAndTechniques.module.scss'
import { getSectionDetails } from '../../utils/utils';
import Details from '../../components/Details/Details';


const ToolsAndTechniques = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(getSectionDetails('tools & techniques'))
  },[])

  return (
    <div className={styles.toolsAndTechniquesContainer}>
      <div className={styles.toolsAndTechniquesWrapper}>
        <p className={styles.heading} id="tools-techniques">
          Tools & Techniques
        </p>
        {
          <Details sections={sections} />
        }
      </div>
    </div>
  )
}

export default ToolsAndTechniques
