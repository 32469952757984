import React from 'react'
import styles from './Landing.module.scss'
import overviews from '../../mockdata/overviews.json'
import Overview from '../../components/Overview/Overview'

const Landing = () => {
  return (
    <div className={styles.mainContainer}>
        {
          overviews.map(overview => <Overview key={overview.id} overview={overview} />)
        }
    </div>
  )
}

export default Landing
