import React from 'react'
import { pink } from '@mui/material/colors';
import SvgIcon from '@mui/material/SvgIcon';
import styles from './AnchorButton.module.scss'

const AnchorButton = () => {
    return (
        <div className={styles.anchorBtn}>
            <SvgIcon sx={{ color: pink[500] }} onClick={() => window.scrollTo({top:0})}>
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
            </SvgIcon>
        </div>
    )
}

export default AnchorButton
