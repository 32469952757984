import { Routes, Route } from "react-router-dom";
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Landing from './pages/Landing/Landing'
import CoreSkill from './pages/CoreSkill/CoreSkill'
import SoftSkill from './pages/SoftSkill/SoftSkill'
import ToolsAndTechniques from './pages/ToolsAndTechniques/ToolsAndTechniques'
import Info from './components/Info/Info'
import AnchorButton from "./components/AnchorButton/AnchorButton";


function App() {
  return (
    <div className="App">
      <Header />
      <Info />
      <AnchorButton />
      <Routes>
        <Route
            path="/"
            element={<Landing />}
        />
        <Route
            path="/core-skills"
            element={<CoreSkill />}
        />
        <Route
            path="/methods-technologies"
            element={<SoftSkill />}
        />
        <Route
            path="/tools-techniques"
            element={<ToolsAndTechniques />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
