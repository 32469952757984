import React, { useEffect, useState } from 'react'
import styles from './SoftSkill.module.scss'
import { getSectionDetails } from '../../utils/utils';
import Details from '../../components/Details/Details';

const SoftSkill = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(getSectionDetails('methods & technologies'))
  },[])

  return (
    <div className={styles.softSkillsContainer}>
      <div className={styles.softSkillsWrapper}>
        <p className={styles.heading} id="methods-technologies">
          Methods & Technologies
        </p>
          {
            <Details sections={sections} />
          }
      </div>
    </div>
  )
}

export default SoftSkill
