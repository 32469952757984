import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import styles from './Details.module.scss'

const Details = ({sections}) => {
  return (
    <div>
      {
        sections && Object.keys(sections).length > 0 ? Object.keys(sections).map(section => {
            return (
                <div key={section}>
                    <p className={styles.subHeading}>
                        {section}
                    </p>
                    <Grid container >
                        {
                        sections[section] && sections[section].length > 0 && sections[section].map(competency => {
                            return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={competency.id} sx={{display: "flex", justifyContent: "center"}}>
                                <Card sx={{ maxWidth: 300, margin:2}}>
                                <CardActionArea>
                                    <a href={competency.targetVideoUrl} rel="noreferrer" target="_blank">
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={competency.thumbnailUrl}
                                        alt={competency.title}
                                    />
                                    
                                    <CardContent sx={{cursor: "default"}}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {competency.title}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Source: {competency.source}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Course Duration: {competency.duration}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {competency.description}
                                    </Typography>
                                    </CardContent>
                                    </a>
                                </CardActionArea>
                                </Card>
                            </Grid>
                            )
                        })
                        }
                    </Grid>
                </div>
            )
        }) : null
      }
    </div>
  )
}

export default Details
