import React from 'react'
import styles from './Info.module.scss'

//replace this image url with actual source
const imageUrl = "https://res.cloudinary.com/dbyioi2qq/q_auto/v1699366636/static/1840x833svg_1681652117_37029owl6_1699366636_88707.jpg";

const Info = () => {
  return (
    <div className={styles.infoContainer}>
        <div className={styles.image}>
            <img src={imageUrl} alt="Learn Business Analysis"/>
        </div>
        <div className={styles.infoWrapper}>
            <div className={styles.infoHeadline}>
                Learn Business Analysis
            </div>
            <div className={styles.infoText}>
                Expand your knowledge in Business Analysis with custom-curated content from our expert team, designed just for you. Access a wealth of information, tutorials, and tips to help you succeed in this field.
            </div>
        </div>
    </div>
  )
}

export default Info
