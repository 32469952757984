import React from 'react'

const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="145" height="8" fill="none">
      <path 
        fill="#000" 
        d="M144.354 4.354a.5.5 0 0 0 0-.708L141.172.464a.501.501 0 0 0-.708.708L143.293 4l-2.829 2.828a.5.5 0 0 0 .708.708l3.182-3.182ZM0 4.5h144v-1H0v1Z"
      />
    </svg>
  )
}

export default Arrow
